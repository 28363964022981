import axios from 'axios'
import { Trans } from '@/plugins/Translation'

let http = null;

class APIService {
  pages = null;
  references = null;
  reviews = null;

  
  constructor ({url}) {
    http = axios.create({
        baseURL: url,
        headers: { 'Content-Type': 'application/json' }
    });
  }

  /* OPTIONS */
  getOptions() {
    if(!this.options){
      this.options = axios.get(process.env.VUE_APP_FORM_API_URL+'/wp-json/out-of-office/get').then(response => response.data);
    }
    return this.options;
  }


  /* REFERENCES */
  getReference(id) {
    return this.getReferences().then(ref => {
      return ref.find(ref => ref.id === parseInt(id));
    });
  }
  
  getReferences() {
    if(!this.references){
      const params = {params: {lang: Trans.currentLanguage}};
      this.references = http.get('references', params).then(response => {
        return response.data.map(item => {
          return  {
            id: item.id,
            galleryLoaded: false,
            gallery: item.acf.gallery.reverse(),
            ...item.acf,
          }
        }).sort((a, b) => (a.title > b.title) ? 1 : -1);
      });
    }
    return this.references;
  }


  /* REVIEWS */
  getReviews() {
    if(!this.reviews){
      const params = {params: {lang: Trans.currentLanguage}};
      this.reviews = http.get('reviews', params).then(response => {
        return response.data.map(item => {
          return  {
            id: item.id,
            ...item.acf,
          }
        });
      });
    }
    return this.reviews;
  }


  /* PAGES */
  getPageById(id) {
    return this.getPages().then(pages => pages.find(page => page.id === id));
  }

  getPageBySlug(name) {
    return this.getPages().then(pages => pages.find(page => page.slug === name));
  }

  getPages() {
    if(!this.pages){
      const params = {params: {lang: Trans.currentLanguage}};
      this.pages = http.get('pages', params).then(response => response.data);
    }
    return this.pages;
  }
}

export default new APIService({
  url: process.env.VUE_APP_API_URL
});
