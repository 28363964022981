import Vue from "vue";
import Router from "vue-router";
import { Trans } from '@/plugins/Translation';

Vue.use(Router);

function load (component) {
  return () => import(`@/views/${component}.vue`)
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
    path: '/:lang',
    component: {
      template: '<router-view></router-view>'
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: '',
        name: 'home',
        component: load('Home')
      }
    ]
  },
  {
    path: '*',
    redirect () {
      return Trans.getUserSupportedLang()
    }
  }
  ]
});